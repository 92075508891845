.App {
  font-family: "Roboto", sans-serif;
  /* background-color: hsl(0deg 4% 85%); */
  height: 100%;
  min-height: 1000px;
}

.App-logo {
  pointer-events: none;
  height: 100px;
  padding: 12px;
}

.App-header {
  background: linear-gradient(90deg, #702822 2%, #d69c12); /* Autumn theme */
  /* background: linear-gradient(135deg, #152238 80%, #23395d, #788a92, white, #FFE484, #FFCC33, #FC9601, #D14009 ); Winter theme */
  /* background: linear-gradient(90deg, green 80%, white, #FFE484, #FFCC33); Spring theme */
  /* background: linear-gradient(
    135deg,
    darkblue,
    70%,
    white,
    red
  ); Summer theme */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  padding-right: 12px;
  text-shadow: 1px 1px black;

  @media screen and (max-width: 1188px) {
    text-shadow: none;
  }
}

.App-link {
  color: black;
}

p,
li {
  font-size: 18px;
}
